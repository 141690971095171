import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import { Collapse, makeStyles } from "@material-ui/core";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { HexColorPicker } from "react-colorful";

import SEO from "../../components/SEO";
import Link from "../../components/v2/Link";
import LazyImage from "../../components/v4/LazyLoadImage/LazyImage";
import FaqSection from "../../components/v4/FaqSection/FaqSection";
import TruestedSection from "../../components/v4/TrustedSection/TruestedSection";
import TestimonialSlider from "../../components/v3/TestimonialSlider/TestimonialSlider";

import whiteCheckCircle from "../../images/v4/whatsappPage/icons/whiteCheckCircle.svg";
import greenCheckCircle from "../../images/v4/whatsappPage/icons/greenCheckCircle.svg";
import linkGeneartionBackground from "../../images/v4/chatButtnGenerator/images/linkGeneartionBackground.png";
import partnerIcon1 from "../../images/v4/aiPage/icons/partnerIcon1.png";
import partnerIcon2 from "../../images/v4/aiPage/icons/partnerIcon2.png";
import partnerIcon3 from "../../images/v4/aiPage/icons/partnerIcon3.png";
import partnerIcon5 from "../../images/v4/aiPage/icons/partnerIcon5.png";
import partnerIcon6 from "../../images/v4/aiPage/icons/partnerIcon6.png";
import partnerIcon7 from "../../images/v4/aiPage/icons/partnerIcon7.png";
import partnerIcon8 from "../../images/v4/aiPage/icons/partnerIcon8.png";
import experienceImg1 from "../../images/v4/chatButtnGenerator/images/experienceImg1.png";
import downArrow from "../../images/v4/aiPage/icons/downArrow.svg";
import whatsappBusinessPartner from "../../images/v3/singaporeCrmHomepage/whatsappBusinessPartner.webp";
import metaBusinessPartner from "../../images/v3/singaporeCrmHomepage/metaBusinessPartner.webp";
import whatsAppFeatureIcon from "../../images/v4/homepage/icons/whatsAppFearureIcon.svg";

import heroSectionBackground from "../../images/v4/chatButtnGenerator/images/heroSectionBackground.png";
import heroSectionImage from "../../images/v4/chatButtnGenerator/images/heroSectionImage.png";
import experienceContainerBackground from "../../images/v4/linkGeneration/images/experienceContainerBackground.png";
import transformBackgroundImage from "../../images/v4/linkGeneration/images/transformBackgroundImage.png";
import partnerSectionBackground from "../../images/v4/linkGeneration/images/partnerSectionBackground.png";
import quickGuideImage from "../../images/v4/chatButtnGenerator/images/quickGuideImage.png";
import instantChatBg1 from "../../images/v4/chatButtnGenerator/images/instantChatBg1.png";
import instantChatBg2 from "../../images/v4/chatButtnGenerator/images/instantChatBg2.png";
import instantChatBg3 from "../../images/v4/chatButtnGenerator/images/instantChatBg3.png";
import instantChatIcon1 from "../../images/v4/chatButtnGenerator/icons/instantChatIcon1.svg";
import instantChatIcon2 from "../../images/v4/chatButtnGenerator/icons/instantChatIcon2.svg";
import instantChatIcon3 from "../../images/v4/chatButtnGenerator/icons/instantChatIcon3.svg";
import MESSAGE_ARROW from "../../images/v4/chatButtnGenerator/icons/pc-widget-message-arrow-icon-disabled.svg";
import MESSAGE_DOT from "../../images/v4/chatButtnGenerator/icons/pc-widget-message-dots-icon-disabled.svg";
import MESSAGE_ICON from "../../images/v4/chatButtnGenerator/icons/pc-widget-message-icon-disabled.svg";
import WHATSAPP from "../../images/v4/chatButtnGenerator/icons/pc-widget-whatsapp-icon-disabled.svg";
import MESSAGE_QUESTION from "../../images/v4/chatButtnGenerator/icons/pc-widget-message-question-icon-disabled.svg";
import copyIcon from "../../images/v4/linkGeneration/icons/copyIcon2.svg";
import close from "../../images/v4/linkGeneration/icons/close.svg";
import growingIcon1 from "../../images/v4/linkGeneration/icons/growingIcon1.svg";
import growingIcon2 from "../../images/v4/linkGeneration/icons/growingIcon2.svg";
import growingIcon3 from "../../images/v4/linkGeneration/icons/growingIcon3.svg";
import growingIcon4 from "../../images/v4/linkGeneration/icons/growingIcon4.svg";
import growingIcon5 from "../../images/v4/linkGeneration/icons/growingIcon5.svg";
import benifitImage from "../../images/v4/chatButtnGenerator/images/benifitImage.png";
import benefitBgImage from "../../images/v4/chatButtnGenerator/images/benefitBgImage.png";
import qs from "qs";

const useStyles = makeStyles((theme) => ({
  whatsappSection: {
    backgroundImage: `url(${heroSectionBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    paddingTop: "50px",
    "& $aiSectionLeft": {
      width: "50%",
      gap: "25px",
    },

    "& .centerContainer": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "32px",
      width: "80%",
      margin: "0 auto",
      textAlign: "center",
      marginTop: "70px",
    },
    "@media only screen and (max-width: 600px)": {
      "& $aiSectionLeft": {
        width: "100%",
      },
      paddingTop: "0px",
      "& .centerContainer": {
        width: "100%",
        marginTop: "30px",
      },
      "& .buttonContaienr": {
        "& .primaryButton": {
          width: "100%",
        },
      },
    },
  },
  aiSectionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      gap: "20px",
    },
  },
  aiSectionLeft: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "40%",
    gap: "20px",
    "& .highlighterText": {
      background: "linear-gradient(90deg, #7AFFAB 0%, #80C8FF 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  aiSectionRight: {
    width: "40%",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  partnerSlide: {
    width: "185px !important",
    "& img": {
      width: "185px",
      filter: "brightness(0.1)",
    },
    "@media only screen and (max-width: 600px)": {
      width: "140px !important",
      marginRight: "30px !important",
      "& img": {
        width: "140px",
        filter: "brightness(0.1)",
      },
    },
  },
  partnerSlider: {
    padding: "10px 0 80px !important",
    "@media only screen and (max-width: 600px)": {
      paddingBottom: "40px !important",
    },
  },

  listContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "24px",
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "15px",
    color: "#A2BED3",
  },
  integrationHeader: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column-reverse !important",
      gap: "30px",
    },
  },
  integrationLeft: {
    width: "42%",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  integrationRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "40%",
    gap: "20px",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  benefitSection: {
    background: "#F6EBFF",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    "& $integrationLeft": {
      width: "55%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      gap: "10px",
    },
    "& $integrationHeader": {
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "row-reverse",
    },
    "@media only screen and (max-width: 600px)": {
      "& $integrationLeft": {
        width: "100%",
      },
      "& $integrationRight": {
        width: "100%",
      },
    },
  },
  pills: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "10px",
    marginTop: "25px",
  },
  pill: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "10px 16px",
    borderRadius: "30px",
    border: "1px solid #02AE7F",
    gap: "10px",
    background: "#fff",
  },
  moreFeatures: {
    "& .header": {
      display: "flex",
      width: "65%",
      gap: "10px",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      textAlign: "left",
    },
    "& .body": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "stretch",
      width: "100%",
      marginTop: "50px",
    },
    "@media only screen and (max-width: 600px)": {
      "& .header": {
        width: "100%",
      },
      "& .body": {
        flexDirection: "column",
        flexWrap: "wrap",
        rowGap: "15px",
      },
    },
    "@media only screen and (min-width: 1900px)": {
      "& .container": {
        paddingTop: "20px",
      },
    },
  },
  featureContainer: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: "32%",
    gap: "20px",
    // boxShadow: "0px 0px 16px #C1D7DB66",
    borderRadius: "16px",
    background: "#fff",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    textAlign: "left",
    "& p": {
      width: "90%",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      "& p": {
        width: "100%",
      },
    },
  },
  experienceContainer: {
    paddingTop: "300px",
    backgroundImage: `url(${experienceContainerBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    "& .centerContainer": {
      width: "70%",
      margin: "0 auto",
      textAlign: "center",
      "@media only screen and (max-width: 600px)": {
        width: "100%",
      },
    },
    "@media only screen and (max-width: 600px)": {
      paddingTop: "500px",
    },
    "@media only screen and (min-width: 1900px)": {
      paddingTop: "400px",
    },
  },
  experienceInnerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "20px",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      gap: "20px",
    },
  },
  experienceContainerLeft: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "24px",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      marginTop: "30px",
    },
  },
  downArrow: {
    transition: "0.3s ease",
    "&.active": {
      transform: "rotate(180deg)",
    },
  },
  body: {
    textAlign: "left",
    margin: "10px 0 0 ",
    "& img": {
      display: "none",
    },
    "@media only screen and (max-width: 600px)": {
      "& img": {
        display: "block",
        width: "100%",
        marginTop: "15px",
      },
    },
  },
  leftNavigation: {
    "@media only screen and (max-width: 600px)": {
      "& img": {
        width: "150px",
      },
    },
  },
  experienceContainerRight: {
    width: "40%",
    minHeight: "450px",
    "& img": {
      width: "100%",
      height: "100%",
    },
    "@media only screen and (max-width: 600px)": {
      // display: "none",
      width: "100%",
      minHeight: "auto",
    },
  },
  faqBox: {
    borderBottom: "1px solid #C4E4DC",
    paddingBottom: "14px",
    position: "relative",
    transition: "0.3s ease",
    "&::after": {
      left: "0",
      bottom: "-2px",
      width: "0",
      height: "3px",
      content: '""',
      position: "absolute",
      transition: "0.3s ease",
      borderRadius: "10px",
      background:
        "linear-gradient(90deg, #25D366 0%, #08B57B 66%, #72E9B2 100%)",
    },
    "&.active::after": {
      transition: "5.5s ease",
      width: "100%",
    },
  },
  faqHeader: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  experienceContainerHeader: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
    "& h2": {
      width: "50%",
    },
    "& p": {
      width: "50%",
    },
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      gap: "20px",
      "& h2": {
        width: "100%",
      },
      "& p": {
        width: "100%",
      },
    },
  },
  supportSystem: {
    backgroundImage: `url(${linkGeneartionBackground})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingBottom: "350px",
    "& .centerContainer": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "16px",
      width: "60%",
      margin: "0 auto",
      textAlign: "center",
      "& p": {
        width: "80%",
      },
      "& img": {
        width: "100%",
        maxWidth: "56px",
        position: "relative",
        top: "10px",
      },
      "& h2": {
        width: "100%",
      },
    },
    "@media only screen and (max-width: 600px)": {
      borderRadius: "0",
      "& .centerContainer": {
        width: "100%",
        "& img": {
          maxWidth: "25px !important",
        },
      },
    },
    "@media only screen and (max-width: 1200px)": {
      "& .centerContainer": {
        "& img": {
          maxWidth: "40px !important",
        },
      },
    },
  },
  broadcastLeftContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "60%",
    gap: "32px",
    textAlign: "left",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  broadcastRightContainer: {
    width: "40%",
    "& img": {
      width: "100%",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      "& img": {
        width: "100%",
      },
    },
  },
  broadcastImg: {
    "& img": {
      width: "100%",
      position: "relative",
      bottom: "0",
    },
  },
  supportBox: {
    top: "100%",
    marginTop: "60px",
    width: "100%",
    display: "flex",
    padding: "40px",
    position: "absolute",
    backgroundImage: `url(${benefitBgImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    alignItems: "center",
    borderRadius: "20px",
    backdropFilter: "blur(30px)",
    justifyContent: "space-between",
    "& $broadcastLeftContainer": {
      width: "45%",
      "@media only screen and (max-width: 600px)": {
        width: "100%",
      },
    },
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      width: "94%",
      padding: "15px",
      marginTop: "20px",
      gap: "20px",
    },
  },
  pointers: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "20px",
  },
  cardPointer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "15px",
    "@media only screen and (max-width: 600px)": {
      alignItems: "center",
    },
  },
  commonPageFooterSection: {
    background: "linear-gradient(89deg, #015352 0%, #00926A 100%)",
    "& .centerContainer": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "32px",
      width: "80%",
      margin: "0 auto",
      textAlign: "center",
    },
    "@media only screen and (max-width: 600px)": {
      "& .centerContainer": {
        width: "100%",
      },
    },
  },
  centerContainer: {
    marginTop: "80px",
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    "@media only screen and (max-width: 600px)": {
      marginTop: "30px",
    },
  },
  rewards: {
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center",
    gap: "20px",
    borderRadius: "24px",
    width: "50%",
    padding: "10px 80px",
    background: "#fff",
    "& .border": {
      borderRight: "1px solid #DBDBDB",
      width: "0",
    },
    "& img": {
      width: "60%",
      "@media only screen and (max-width: 600px)": {
        width: "45%",
      },
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      padding: "20px",
    },
  },
  growingBusiness: {
    background: "#2F0056",
    paddingBottom: "320px",
    position: "relative",
    "& .header": {
      width: "55%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      textAlign: "left",
      gap: "32px",
    },
    "& $featureContainer": {
      gap: "20px",
    },
    "@media only screen and (max-width: 600px)": {
      "& .header": {
        width: "100%",
      },
      paddingBottom: "220px",
    },
  },
  broadcastContainerInner: {
    gap: "30px",
    width: "100%",
    display: "flex",
    padding: "0 8rem 0rem 8rem",
    // padding: "0 0rem .5rem 0rem",
    alignItems: "stretch",
    justifyContent: "flex-start",
    "& swiper-wrapper": {
      alignItems: "stretch !important",
    },
    // cursor: "grab",
    // "&::-webkit-scrollbar-track": {
    //   "-webkit-box-shadow": "none",
    //   backgroundColor: "#EDF1F6",
    // },

    // "&::-webkit-scrollbar": {
    //   width: "5px",
    //   height: "5px",
    //   borderRadius: "50%",
    //   backgroundColor: "#EDF1F6",
    // },

    // "&::-webkit-scrollbar-thumb": {
    //   backgroundColor: "#D2DBE4",
    //   borderRadius: "50px",
    // },
    "& $featureContainer": {
      width: "31%",
      height: "auto  !important",
    },
    "@media only screen and (max-width: 600px)": {
      padding: "0 15px !important",
      paddingBottom: "10px !important",
    },
    "@media only screen and (min-width: 1900px)": {
      paddingLeft: "23rem !important",
      paddingRight: "23rem !important",
    },
  },
  absoulteCard: {
    position: "absolute",
    margin: "0 auto",
    width: "100%",
    top: "75%",
  },
  stripSection: {
    paddingTop: "280px",
    backgroundImage: `url(${partnerSectionBackground})`,
    backgroundPosition: "top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    "& .centerContainer": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "@media only screen and (max-width: 600px)": {
      paddingTop: "180px",
      "& .centerContainer": {
        textAlign: "center",
      },
    },
  },
  formContainer: {
    padding: "40px 32px",
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    width: "50%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "45px",
    "@media only screen and (max-width: 600px)": {
      padding: "20px",
      gap: "30px",
      width: "100%",
    },
  },
  formHeader: {},
  formBody: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "30px",
    "& .primaryButton": {
      width: "100%",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      gap: "25px",
    },
  },
  inputBoxContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    gap: "30px",
    position: "relative",
    "&::before": {
      left: "20px",
      width: "0",
      height: "100%",
      content: '""',
      position: "absolute",
      borderRight: "2px dashed #cccccc",
      top: "40px",
      "@media only screen and (max-width: 600px)": {
        left: "15px",
        top: "30px",
      },
    },
    "&:nth-child(5):before": {
      display: "none",
    },
    "&:nth-child(6):before": {
      display: "none",
    },
    "&:last-child:before": {
      display: "none",
    },
    "&:last-child": {
      "& $formStep": {
        background: "transparent",
      },
    },
    "&:nth-child(6)": {
      "& $formStep": {
        background: "transparent",
      },
    },
    "@media only screen and (max-width: 600px)": {
      gap: "15px",
    },
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "15px",
    width: "calc(100% - 70px)",
    "& .primaryButton": {
      width: "100%",
      "@media only screen and (max-width: 600px)": {
        width: "100%",
      },
    },
    "@media only screen and (max-width: 600px)": {
      width: "calc(100% - 45px)",
      gap: "10px",
    },
  },
  inputContainerHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "10px",
  },
  inputContainerBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "10px",
    width: "100%",
  },
  input: {
    width: "100%",
    border: "1px solid #D9D9D9",
    outline: "none",
    padding: "15px 10px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    WebkitAppearance: "none",
    MozAppearance: "textfield",
    margin: "0",
    justifyContent: "space-between",
    "&::placeholder": {
      color: "#8A8A8A",
    },

    "&::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      backgroundColor: "#EDF1F6",
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#D2DBE4",
      borderRadius: "50px",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
    "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      MozAppearance: "none",
      appearance: "none",
      margin: 0,
    },
  },
  formStep: {
    height: "40px",
    minWidth: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#02AE7F",
    borderRadius: "50%",
    "@media only screen and (max-width: 600px)": {
      width: "30px",
      height: "30px",
      minWidth: "30px",
    },
  },
  formFooter: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "57%",
    "& .primaryButton": {
      width: "71%",
      marginLeft: "70px",
    },
  },
  whatsappButton: {
    border: "2px solid #02AE7F",
    background: "transparent",
    padding: "15px 24px",
    borderRadius: "100px",
    cursor: "pointer",
    outline: "none",
    textDecoration: "none",
    color: "#007253",
    display: "flex",
    lineHeight: "1.75 !important",
    alignItems: "center",
    justifyContent: "center",
    transition: "0.3s ease",
    width: "80%",
    fontWeight: "600",
    zIndex: "0",
    gap: "10px",
    textDecoration: "none !important",
    "&:hover": {
      color: "#007253",
    },
    "& img": {
      width: "28px",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  wpFaq: {
    "@media only screen and (max-width: 600px)": {
      "& section": {
        padding: "0px 0 0 !important",
      },
    },
  },
  formBoxContainerBox: {
    width: "100%",
    display: "flex",
    alignItems: "stretch",
    justifyContent: "flex-start",
    borderRadius: "25px",
    overflow: "hidden",
    boxShadow: "0px 0px 32px #6DE7C56E",
    marginTop: "60px",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
    },
  },
  qrContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "50%",
    height: "auto",
    padding: "40px 0",
    background: "linear-gradient(180deg, #CEFFEA 0%, #CBE8FF 100%)",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      padding: "20px",
    },
  },
  qrContainerInner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "60%",
    gap: "25px",
    pointerEvents: "none",
    textAlign: "center",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  qrCodeContainer: {
    border: "2px solid #B4DAEB",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "16px",
    padding: "10px",
    "& img": {
      height: "196px",
      width: "196px",
      borderRadius: "16px",
    },
  },
  downloadQrButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
    color: "#007188",
    textDecoration: "underline",
    "&:hover": {
      color: "#007188",
    },
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    gap: "35px",
    "& input": {
      padding: 0,
      height: "0",
      width: "0",
      cursor: "pointer",
      position: "relative",
    },
    "& label": {
      position: "relative",
      cursor: "pointer",
    },
    "& input::before": {
      content: "''",
      backgroundColor: "#fff",
      border: "1px solid #0291AE",
      height: "20px",
      width: "20px",
      borderRadius: "5px",
      background: "#fff",
      display: "inline-block",
      position: "relative",
      verticalAlign: "middle",
      cursor: "pointer",
      marginRight: "5px",
    },
    "& input:checked::after": {
      top: "3px",
      left: "7px",
      width: "5px",
      border: "1px solid #fff",
      height: "12px",
      content: "''",
      position: "absolute",
      transform: "rotate(45deg)",
      borderWidth: "0 2px 2px 0",
    },
    "& input:checked::before": {
      background: "#0291AE",
    },
    "@media only screen and (max-width: 600px)": {
      gap: "25px",
      width: "100%",
    },
    "& a": {
      color: "#208EEC",
      "&:hover": {
        color: "#208EEC",
      },
    },
  },
  checkboxContainerOuter: {
    width: "100%",
    display: "flex",
    gap: "10px",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  wpFaq: {
    "@media only screen and (max-width: 600px)": {
      "& section": {
        padding: "0px 0 0 !important",
      },
    },
  },
  stepBox: {
    width: "100%",
    borderBottom: "1px solid #C4E4DC",
    paddingBottom: "14px",
    position: "relative",
    transition: "0.3s ease",
    "&::after": {
      left: "0",
      bottom: "-2px",
      width: "0",
      height: "3px",
      content: '""',
      position: "absolute",
      transition: "0.3s ease",
      borderRadius: "10px",
      background:
        "linear-gradient(90deg, #25D366 0%, #08B57B 66%, #72E9B2 100%)",
    },
    "&.active::after": {
      transition: "5.5s ease",
      width: "100%",
    },
  },
  stepBoxHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
    width: "100%",
    cursor: "pointer",
  },
  stepBoxBody: {
    padding: "10px 0",
    width: "100%",
    "& .MuiCollapse-wrapperInner": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      gap: "25px",
      width: "100%",
    },
  },
  optionInputContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "10px",
    width: "100%",
  },
  optionInput: {
    width: "100%",
    border: "1px solid #D9D9D9",
    margin: "0",
    display: "flex",
    outline: "none",
    padding: "15px 10px",
    alignItems: "center",
    borderRadius: "8px",
    MozAppearance: "textfield",
    justifyContent: "space-between",
    WebkitAppearance: "none",
    resize: "none",
  },
  flexOptions: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "30px",
    width: "100%",
    "& $optionInputContainer": {
      width: "50%",
    },
  },
  colorInput: {
    display: "flex",
    gap: "10px",
    width: "100%",
    position: "relative",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  color: {
    height: "100%",
    width: "100%",
    padding: "5px",
    borderRadius: "8px",
  },
  radioGroup: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  radioOption: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    justifyContent: "flex-start",
    "& input": {
      padding: 0,
      height: "25px",
      width: "25px",
      cursor: "pointer",
      position: "relative",
    },
    "& label": {
      position: "relative",
      cursor: "pointer",
    },
    "& input::before": {
      content: "''",
      backgroundColor: "#fff",
      border: "1.5px solid #0291AE",
      borderRadius: "50%",
      height: "25px",
      width: "25px",
      background: "#fff",
      display: "inline-block",
      position: "relative",
      verticalAlign: "middle",
      cursor: "pointer",
      marginRight: "5px",
    },
    "& input:checked::after": {
      top: "4px",
      left: "4px",
      width: "17px",
      borderRadius: "50%",
      background: " #0291AE",
      height: "17px",
      content: "''",
      position: "absolute",
    },
    "& input:checked::before": {},
  },
  chatButtons: {
    display: "flex",
    width: "100%",
    gap: "10px",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  chatButton: {
    borderRadius: "50%",
    border: "2px solid #EEEEEE",
    height: "56px",
    width: "56px",
    borderRadius: "50%",
    padding: "5px",
    transition: "0.3s ease",
    "&.active": {
      border: "2px solid #0291AE",
    },
  },
  chatIcon: {
    background: "linear-gradient(180deg, #02AE7F 0%, #02AE2A 100%)",
    height: "42px",
    width: "42px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: "20px",
      height: "20px",
    },
  },
  colorPickerContainer: {
    top: "0",
    left: "0",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    opacity: "0",
    zIndex: "1",
    position: "absolute",
    transition: "0.3s ease",
    visibility: "hidden",
    padding: "10px",
    background: "#fff",
    boxShadow: "0 0 10px #00000040",
    borderRadius: "10px",
    "&.active": {
      visibility: "visible",
      opacity: "1",
    },
    "@media only screen and (max-width: 600px)": {
      "&.mobileColorPicker": {
        top: "0%",
        right: "0",
        left: "auto",
      },
    },
  },
  colorContainer: {
    height: "56px",
    width: "56px",
    padding: "5px",
    borderRadius: "8px",
    border: "1px solid #D9D9D9",
  },
  togglerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
  },
  toggler: {
    width: "45px",
    height: "25px",
    borderRadius: "28px",
    background: "#D9D9D9",
    overflow: "hidden",
    position: "relative",
    padding: "4px",
    transition: "0.3s ease",
    cursor: "pointer",
    "&.active": {
      background: "#02AE7F",
      "& $toggle": {
        left: "55%",
      },
    },
  },
  toggle: {
    height: "100%",
    width: "17px",
    background: "#fff",
    borderRadius: "100%",
    position: "relative",
    left: "0",
    transition: "0.3s ease",
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "80%",
    gap: "35px",
    "& input": {
      padding: 0,
      height: "0",
      width: "0",
      cursor: "pointer",
      position: "relative",
    },
    "& label": {
      position: "relative",
      cursor: "pointer",
    },
    "& input::before": {
      content: "''",
      backgroundColor: "#fff",
      border: "1px solid #0291AE",
      height: "20px",
      width: "20px",
      borderRadius: "5px",
      background: "#fff",
      display: "inline-block",
      position: "relative",
      verticalAlign: "middle",
      cursor: "pointer",
      marginRight: "5px",
    },
    "& input:checked::after": {
      top: "3px",
      left: "7px",
      width: "5px",
      border: "1px solid #fff",
      height: "12px",
      content: "''",
      position: "absolute",
      transform: "rotate(45deg)",
      borderWidth: "0 2px 2px 0",
    },
    "& input:checked::before": {
      background: "#0291AE",
    },
    "@media only screen and (max-width: 600px)": {
      gap: "25px",
      width: "100%",
    },
    "& a": {
      color: "#208EEC",
      "&:hover": {
        color: "#208EEC",
      },
    },
  },
  widgetCode: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "24px",
    width: "100%",
  },
  widgetCodeContainer: {
    position: "relative",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "5px",
    background: "#F1FFF9",
    padding: "10px",
    borderRadius: "8px",
    width: "100%",
    overflow: "auto",
    border: "1px solid #D9D9D9",
    maxHeight: "400px",
    fontFamily: '"Courier New", monospace !important',
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "none",
      backgroundColor: "#EDF1F6",
    },

    "&::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
      borderRadius: "50%",
      backgroundColor: "#EDF1F6",
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#D2DBE4",
      borderRadius: "50px",
    },
  },
  copyIcon: {
    position: "sticky",
    top: "10px",
    right: "10px",
    cursor: "pointer",
    background: "#fff",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  closeIcon: {
    position: "relative",
    width: "20px",
    height: "20px",
    cursor: "pointer",
  },
  copiedToast: {
    position: "fixed",
    bottom: "-10px",
    left: "50%",
    transform: "translateX(-50%) scale(0)",
    background: "#02AE7F",
    color: "#fff",
    opacity: "0",
    visibility: "hidden",
    padding: "10px 20px",
    borderRadius: "5px",
    width: "max-content",
    zIndex: "1000",
    transition: "0.3s ease",
    "& p": {
      margin: "0",
    },
    "&.active": {
      opacity: "1",
      bottom: "50px",
      visibility: "visible",
      transform: "translateX(-50%) scale(1)",
    },
  },
}));

const ChatButtonGenerator = () => {
  const classes = useStyles();
  const [activeAccordian, setActiveAccordian] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [byDefaultOpen, setByDefaultOpen] = useState(false);
  const [toggleBrandColor, setToggleBrandColor] = useState(false);
  const [brandColor, setBrandColor] = useState("#02AE7F");
  const [toggleButtonColor, setToggleButtonColor] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [brandName, setBrandName] = useState("Pepper Cloud");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [brandUrl, setBrandUrl] = useState("");
  const [widgetCode, setWidgetCode] = useState("");
  const [consent, setConsent] = useState(true);
  const [preFilledMessage, setPreFilledMessage] = useState("");
  const [activePosition, setActivePosition] = useState("right");
  const [welcomeMessage, setWelcomeMessage] = useState(
    "Hello! How can we assist you today?"
  );
  const [buttonText, setButtonText] = useState("Chat with us");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [brandError, setBrandError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [welcomeMessageError, setWelcomeMessageError] = useState("");
  const [copied, setCopied] = useState(false);
  const [consentError, setConsentError] = useState("");
  const [buttonTextError, setButtonTextError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [chatWidgetIcons, setChatWidgetIcons] = useState([
    {
      iconName: "chatWidgetIcon1",
      colorCode: "#208EEC",
      iconSrc: MESSAGE_DOT,
      iconId: "pc-widget-message-dots-icon-white",
      altText: "WhatsApp Chat Button",
    },
    {
      iconName: "chatWidgetIcon2",
      colorCode: "#009A99",
      iconSrc: MESSAGE_ARROW,
      iconId: "pc-widget-message-arrow-icon-white",
      altText: "WhatsApp Chat Widget",
    },
    {
      iconName: "chatWidgetIcon3",
      colorCode: "#9E5AD5",
      iconSrc: MESSAGE_ICON,
      iconId: "pc-widget-message-icon-white",
      altText: "WhatsApp Live chat button ",
    },
    {
      iconName: "chatWidgetIcon4",
      colorCode: "#25D366",
      iconSrc: WHATSAPP,
      iconId: "pc-widget-whatsapp-icon-white",
      altText: "Chat button generator",
    },
    {
      iconName: "chatWidgetIcon5",
      colorCode: "#FF8958",
      iconSrc: MESSAGE_QUESTION,
      iconId: "pc-widget-message-question-icon-white",
      altText: "WhatsApp live chat widget",
    },
  ]);

  let defaultBrandUrl =
    "https://qa-crmb2b.s3.ap-southeast-1.amazonaws.com/widget/64095f8968e1c9290308f90e/cc4ccb7f-2133-4832-80e8-8d0f749aaa29.png";

  const [selectedIcon, setSelectedIcon] = useState(chatWidgetIcons[0]);
  const [buttonColor, setButtonColor] = useState(selectedIcon.colorCode);
  useEffect(() => {
    setSelectedIcon((prev) => ({ ...prev, colorCode: buttonColor }));
    setChatWidgetIcons((prev) =>
      prev.map((icon) =>
        icon.iconName === selectedIcon.iconName
          ? { ...icon, colorCode: buttonColor }
          : icon
      )
    );
  }, [buttonColor, selectedIcon.iconName]);

  const createLead = async () => {
    try {
      const response = await fetch(
        "https://backend.peppercloud.com/api/v2/web-forms/58bafeec-a7dd-4c6e-97c9-88d290c12618/view"
      );
      const data = await response.json();
      const [url, queries] = window.location.href.split("?");
      let queryObj = {};
      if (queries) {
        queryObj = qs.parse(queries);
      }
      const payload = {
        form: data.data.uid,
        token: data.data.token,
        records: {
          WF_1: name,
          WF_2: email,
          WF_4: "+" + phoneNumber,
          WF_3: brandName,
        },
        recaptchaToken: null,

        marketingDetails: {
          F143: url,
          F144: queryObj.utm_campaign || "",
          F145: queryObj.utm_source || "",
          F146: queryObj.utm_medium || "",
          F147: queryObj.utm_term || "",
          F148: queryObj.utm_content || "",
          F151: document.title,
        },
      };

      await fetch("https://backend.peppercloud.com/api/v2/web-form-response", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
    } catch (error) {}
  };
  // Function to validate email
  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  const createChatWidget = async () => {
    setNameError("");
    setEmailError("");
    setBrandError("");
    setPhoneError("");
    setConsentError("");
    setWelcomeMessageError("");
    setButtonTextError("");
    setIsLoading(true);
    let hasError = false;

    if (name === "") {
      setNameError("Name is required");
      hasError = true;
      setActiveStep(1);
    }
    if (email === "") {
      setEmailError("Email is required");
      hasError = true;
      setActiveStep(1);
    } else if (!validateEmail(email)) {
      setEmailError("Invalid email");
      hasError = true;
      setActiveStep(1);
    }
    if (brandName === "") {
      setBrandError("Brand name is required");
      hasError = true;
      setActiveStep(1);
    }
    if (phoneNumber === "") {
      setPhoneError("Phone number is required");
      hasError = true;
      setActiveStep(1);
    } else if (phoneNumber === "") {
      setPhoneError("Invalid phone number");
      hasError = true;
      setActiveStep(1);
    }
    if (welcomeMessage === "") {
      setWelcomeMessageError("Welcome message is required");
      hasError = true;
      setActiveStep(1);
    }
    if (!consent) {
      setConsentError("Please accept the terms and conditions");
      hasError = true;
    }
    if (buttonText === "") {
      setButtonTextError("Button text is required");
      hasError = true;
      setActiveStep(0);
    }

    if (hasError) {
      setIsLoading(false);
      return;
    } else {
      createLead();
      setWidgetCode(true);
      window.scrollBy({
        top: 400,
        behavior: "smooth",
      });
    }
  };

  const partners = [
    { icon: partnerIcon1, alt: "caffeine solutions - Pepper Cloud Customer" },
    { icon: partnerIcon2, alt: "cuscapi - Pepper Cloud Customer" },
    { icon: partnerIcon3, alt: "TechData - Pepper Cloud Customer" },
    { icon: partnerIcon5, alt: "sis - Pepper Cloud Customer" },
    {
      icon: partnerIcon6,
      alt: "mustard seed systems corporation - Pepper Cloud Customer",
    },
    {
      icon: partnerIcon7,
      alt: "Singapore Maritime Foundation - Pepper Cloud Customer",
    },
    { icon: partnerIcon8, alt: "urban moods - Pepper Cloud Customer" },
    { icon: partnerIcon1, alt: "caffeine solutions - Pepper Cloud Customer" },
    { icon: partnerIcon2, alt: "cuscapi - Pepper Cloud Customer" },
    { icon: partnerIcon3, alt: "TechData - Pepper Cloud Customer" },
    { icon: partnerIcon5, alt: "sis - Pepper Cloud Customer" },
    {
      icon: partnerIcon6,
      alt: "mustard seed systems corporation - Pepper Cloud Customer",
    },
    {
      icon: partnerIcon7,
      alt: "Singapore Maritime Foundation - Pepper Cloud Customer",
    },
    { icon: partnerIcon8, alt: "urban moods - Pepper Cloud Customer" },
  ];
  const featureList = [
    {
      icon: instantChatIcon1,
      background: instantChatBg1,
      heading: "Why WhatsApp chat widgets?",
      text: "They allow your website visitors to connect with you effortlessly through a WhatsApp chat button and converse with you in real-time.",
    },
    {
      icon: instantChatIcon2,
      background: instantChatBg2,
      heading: "How do live chat widget work?",
      text: "Embed the widget on your website. With just one click, your customers can start a conversation with you from any page, reducing friction.",
    },
    {
      icon: instantChatIcon3,
      background: instantChatBg3,
      heading: "Why to use free WhatsApp button generator?",
      text: "Generate a WhatsApp live chat widget in seconds by entering your number, adding a welcome message, and customising the look.",
    },
  ];
  const growingList = [
    {
      icon: growingIcon1,
      background: "#F9F1FF",
      heading: "All-in-one platform",
      text: "Capture conversations, associate it with the contact, and access all the information from a centralised place.",
    },
    {
      icon: growingIcon2,
      background: "#F4FAFF",
      heading: "User-friendly interface",
      text: "Create and customise your WhatsApp chat widget effortlessly, even without technical expertise.",
    },
    {
      icon: growingIcon4,
      background: "#FFF5F1",
      heading: "Advanced analytics",
      text: "Connect the widget with the CRM and track customer interactions, response times, engagement rates, and more.",
    },
    {
      icon: growingIcon3,
      background: "#F9F1FF",
      heading: "AI-powered assistance",
      text: "Auto-send greeting messages, and automate responses to common queries with AssistAI.",
    },
    {
      icon: growingIcon5,
      background: "#FFF5E8",
      heading: "Automated chat flows",
      text: "Automatically capture leads, enable easy bookings, and more with automated chat flows.",
    },
  ];
  const experienceList = [
    {
      title: "Education and coaching",
      alt: "WhatsApp link generator for education and coaching",
      desc: "Allow students to ask questions and receive guidance instantly. Integrate WhatsApp links on course pages and admission forms to enhance engagement and support.",
    },
    {
      title: "Retail and e-commerce",
      alt: "WhatsApp link generator for retail and eCommerce",
      desc: "Allow customers to ask product questions, track orders, and get real-time support with a single click. Enhance shopping experiences with instant communication.",
    },
    {
      title: "Real estate",
      alt: "WhatsApp link generator for real estate",
      desc: "Enable potential buyers to connect with agents instantly. Add a chat button to property listings, virtual tours, and landing pages to boost lead conversions.",
    },
    {
      title: "Travel and hospitality",
      alt: "WhatsApp link generator for travel and hospitality",
      desc: "Offer instant travel assistance, itinerary updates, and booking support. A chat button on your website ensures seamless communication for travelers.",
    },
    {
      title: "Healthcare and clinics",
      alt: "WhatsApp link generator for healthcare and clinics",
      desc: "Allow patients to book appointments, ask health-related queries, and receive follow-up reminders through instant WhatsApp chat on your website.",
    },
  ];

  const faqs = [
    {
      question: "What is a WhatsApp chat button?",
      answer: (
        <>
          A WhatsApp chat button is a clickable button placed on your website
          that allows visitors to start a conversation with your business on
          WhatsApp instantly. It eliminates friction by enabling direct
          messaging without saving numbers.
        </>
      ),
    },
    {
      question: "What is a WhatsApp chat widget?",
      answer: (
        <>
          A WhatsApp chat widget is a floating button that stays visible on your
          website, making it easier for visitors to reach out via WhatsApp
          anytime. It can be customised with a welcome message, pre-filled text,
          and branding options.
        </>
      ),
    },
    {
      question: "Can you use WhatsApp for live chat?",
      answer: (
        <>
          Yes, WhatsApp can be used for live chat. With a WhatsApp chat widget,
          businesses can offer real-time support, answer customer queries, and
          enhance engagement—all through WhatsApp.
        </>
      ),
    },
    {
      question: "Should you use a WhatsApp button, widget, or both?",
      answer: (
        <>
          It depends on your business needs. A WhatsApp button is great for
          specific landing pages, emails, or ads, while a floating widget
          ensures visibility across your entire website. Using both can maximise
          customer interactions.
        </>
      ),
    },
    {
      question: "Why should you have a WhatsApp chat widget on your website?",
      answer: (
        <>
          A WhatsApp chat widget can significantly enhance your customer
          interaction. Here are some key benefits:
          <ul>
            <li>
              <strong>Instant support:</strong> Allow customers to chat with you
              in real-time without searching for contact details.
            </li>
            <li>
              <strong>Higher engagement:</strong> Visitors are more likely to
              reach out when a chat option is easily accessible.
            </li>
            <li>
              <strong>Increased conversions:</strong> Personalised interactions
              drive better sales and customer satisfaction.
            </li>
          </ul>
        </>
      ),
    },
    {
      question: "How do I add a WhatsApp chat button to my website for free?",
      answer:
        "With Pepper Cloud free WhatsApp chat button generator, you can create and install the button in minutes. Simply generate the button code and embed it on your website—no coding required!",
    },
    {
      question: "How do I create a WhatsApp chat widget for free?",
      answer:
        "Use Pepper Cloud free WhatsApp chat widget generator to customise and create a floating widget. Enter your WhatsApp number, choose a welcome message, and copy the generated code to add it to your website.",
    },
    {
      question: "What is a floating widget for a website?",
      answer:
        "A floating widget is a chat button that remains visible as visitors scroll through your website. It ensures easy access to WhatsApp chat without interrupting their browsing experience.",
    },
    {
      question:
        "How can I add a free WhatsApp chat button to social media ads?",
      answer: (
        <>
          To add a chat button to your social media ads, create a click-to-chat
          WhatsApp link using{" "}
          <a
            href="https://peppercloud.com/whatsapp-crm-features/free-whatsapp-link-generator/"
            target="_blank"
          >
            Pepper Cloud free WhatsApp link generator tool
          </a>
          . Insert this link into your ad's CTA or description, allowing users
          to start a conversation instantly when they click.
        </>
      ),
    },
    {
      question: "Can I customise the appearance of the WhatsApp chat widget?",
      answer:
        "Yes, Pepper Cloud tool lets you customise the widget's size, position, colour, and pre-filled messages to match your branding.",
    },
    {
      question: "Is coding knowledge required to install the widget?",
      answer:
        "No, Pepper Cloud provides a simple code snippet that you can copy and paste onto your website—no technical skills needed.",
    },
    {
      question: "Can the widget be used for multiple WhatsApp numbers?",
      answer:
        "Yes, you can create multiple chat widgets for different WhatsApp numbers to support various teams or departments.",
    },
    {
      question: "How do I know if the widget is working correctly?",
      answer:
        "Once embedded, test the widget by clicking on it to ensure it opens a WhatsApp chat as expected. If any issues arise, you can recheck the setup or contact Pepper Cloud support.",
    },
    {
      question:
        "Can I track chat interactions through the WhatsApp chat widget?",
      answer: (
        <>
          Yes, if you are using Pepper Cloud CRM, all interactions initiated via
          the widget are tracked within the system, helping you manage leads and
          follow-ups efficiently. You can{" "}
          <a href="https://peppercloud.com/contact" target="_blank">
            book a demo for Pepper Cloud CRM
          </a>
          .
        </>
      ),
    },
  ];

  const { ref, inView } = useInView({
    threshold: 0.5, // adjust as needed
    triggerOnce: false,
  });

  useEffect(() => {
    let timer;
    if (inView) {
      timer = setInterval(() => {
        setActiveAccordian((prev) => (prev + 1) % experienceList.length);
      }, 5500);
    }
    // Clear the timer when the component unmounts or when inView changes.
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [inView, experienceList.length]);

  const scrollToSection = () => {
    document.getElementById("formSection").scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <main>
      <SEO
        title={
          "Free WhatsApp Chat Button & Widget Generator | Add Live Chat to Your Website"
        }
        pathname={
          "https://peppercloud.com/whatsapp-crm-features/free-whatsapp–click-to-live-chat-button-widget-generator"
        }
        keywords="whatsapp chat button,whatsapp chat button on website,whatsapp chat widget,click to chat whatsapp,free whatsapp chat widget for website,whatsapp button generator​,add whatsapp live chat to website"
        description={
          "Instantly create a free WhatsApp click-to-chat button and widget with Pepper Cloud WhatsApp chat button & widget generator for your website and boost customer engagement"
        }
        ogData={{
          "og:image": [
            "/meta/og/1X1/home.png",
            "/meta/og/1024X512/home.png",
            "/meta/og/1200X630/home.png",
          ],
        }}
        jsonLd={{
          "@context": "https://schema.org",
          "@type": "Organization",
          name: "Pepper Cloud",
          legalName: "Pepper Cloud PTE LTD",
          url: "https://peppercloud.com/",
          logo: "https://peppercloud.com/icons/logo.png",
          foundingDate: "2017",
          address: [
            {
              "@type": "PostalAddress",
              streetAddress: "#04-01/02",
              addressLocality: "Blk 79 Ayer Rajah Crescent",
              addressRegion: "SG",
              postalCode: "139955",
              addressCountry: "Singapore",
            },
            {
              "@type": "PostalAddress",
              streetAddress:
                "2nd Floor, Salarpuria Tower 1, 7th Block Koramangala",
              addressLocality: "Bengaluru",
              addressRegion: "KA",
              postalCode: "560095",
              addressCountry: "India",
            },
          ],
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer support",
            telephone: "+6566562616",
            email: ["sales@peppercloud.com", "support@peppercloud.com"],
          },
          sameAs: [
            "https://twitter.com/peppercloudpte",
            "https://www.linkedin.com/company/peppercloud/",
            "https://www.facebook.com/PepperCloudPteLtd",
            "https://www.instagram.com/pepper.cloud/",
          ],
        }}
      />
      <div className={clsx(classes.copiedToast, copied && "active")}>
        <p>Code copied to clipboard</p>
      </div>
      <section className={classes.whatsappSection}>
        <div className="container">
          <div className={classes.aiSectionContainer}>
            <div className={classes.aiSectionLeft}>
              <motion.h1
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-semibold font-18"
                style={{ color: "#97D2FF" }}
              >
                Free WhatsApp chat button and widget generator
              </motion.h1>
              <motion.h2
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-semibold font-56 font-white"
              >
                Boost conversations with free WhatsApp chat widget
              </motion.h2>
              <motion.p
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-regular font-18"
                style={{ color: "#A2BED3" }}
              >
                Create a WhatsApp chat button and add it to your landing pages,
                product pages, contact pages, blog posts, and more. Be where
                your customers are and make customer interactions seamless.
              </motion.p>
              <div className={classes.listContainer}>
                <div className={classes.listItem}>
                  <LazyImage
                    source={greenCheckCircle}
                    alt={"pointer"}
                    className={classes.pointerImg}
                  />
                  <p className="font-wix-regular font-18">
                    Let website visitors connect with you in one click
                  </p>
                </div>
                <div className={classes.listItem}>
                  <LazyImage
                    source={greenCheckCircle}
                    alt={"pointer"}
                    className={classes.pointerImg}
                  />
                  <p className="font-wix-regular font-18">
                    Boost conversions with real-time chat support
                  </p>
                </div>
                <div className={classes.listItem}>
                  <LazyImage
                    source={greenCheckCircle}
                    alt={"pointer"}
                    className={classes.pointerImg}
                  />
                  <p className="font-wix-regular font-18">
                    Customise the widget to match your brand identity
                  </p>
                </div>
              </div>
              <div className="buttonContaienr">
                {/* <Link
                  to={
                    "/whatsapp-crm?utm_source=Homepage&utm_medium=CTA&utm_content=Explore+WhatApp+CRM"
                  }
                  initial={{ opacity: 1, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  className="transparentButton font-wix-medium font-16"
                >
                  Explore WhatApp CRM
                </Link> */}
                <button
                  onClick={() => scrollToSection()}
                  initial={{ opacity: 1, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  className=" primaryButton font-wix-medium font-18 font-white"
                >
                  Create my WhatsApp chat button
                </button>
              </div>
            </div>
            <motion.div
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className={classes.aiSectionRight}
            >
              <LazyImage
                source={heroSectionImage}
                width={"100%"}
                alt={"Free WhatsApp chat button and widget generator"}
              />
            </motion.div>
          </div>
          <div className={classes.centerContainer}>
            <div className={classes.rewards}>
              <img
                src={whatsappBusinessPartner}
                alt="WhatsApp Business Partner"
              />
              <div className="border" />
              <img src={metaBusinessPartner} alt="Meta Business Partner" />
            </div>
          </div>
        </div>
      </section>
      <section className={clsx(classes.benefitSection)}>
        <div className="container">
          <div className={classes.integrationHeader}>
            <div className={classes.integrationRight}>
              <LazyImage
                source={quickGuideImage}
                alt={"What is a WhatsApp chat button generator?"}
                width={"100%"}
                className={classes.integrationRightImage}
              />
            </div>
            <div className={classes.integrationLeft}>
              <motion.div
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="sectionTag"
              >
                <p className="font-18  font-wix-semibold color-black2">
                  Quick guide
                </p>
              </motion.div>
              <motion.h2
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-semibold font-56 color-black1"
              >
                What is a WhatsApp chat button generator?
              </motion.h2>
              <motion.p
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-regular font-18 color-black2 mt20"
              >
                WhatsApp chat button generator is a tool that enables businesses
                to create a custom chat button for website. It can be customised
                as per the needs. Pepper Cloud WhatsApp chat button generator is
                a free, no-code tool that allows you to create a floating
                WhatsApp button for your website and enables users to start a
                chat with your business instantly.
              </motion.p>
              <div className={classes.pills}>
                <div className={classes.pill}>
                  <LazyImage
                    source={greenCheckCircle}
                    alt={"pointer"}
                    className={classes.pointerImg}
                  />
                  <p className="font-wix-medium font-16 color-black2">
                    100% Free
                  </p>
                </div>
                <div className={classes.pill}>
                  <LazyImage
                    source={greenCheckCircle}
                    alt={"pointer"}
                    className={classes.pointerImg}
                  />
                  <p className="font-wix-medium font-16 color-black2">
                    Customisable widget
                  </p>
                </div>
                <div className={classes.pill}>
                  <LazyImage
                    source={greenCheckCircle}
                    alt={"pointer"}
                    className={classes.pointerImg}
                  />
                  <p className="font-wix-medium font-16 color-black2">
                    Instant setup
                  </p>
                </div>
                <div className={classes.pill}>
                  <LazyImage
                    source={greenCheckCircle}
                    alt={"pointer"}
                    className={classes.pointerImg}
                  />
                  <p className="font-wix-medium font-16 color-black2">
                    No coding required
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={classes.moreFeatures}>
        <div className="container">
          <div className="header">
            <motion.h2
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-medium font-56 color-black1"
            >
              Say goodbye to long forms, hello to instant chats!
            </motion.h2>
            <motion.p
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-regular font-18 color-black1"
            >
              Make communication seamless with Pepper Cloud free WhatsApp chat
              button generator. Let your visitors connect with you instantly,
              improving engagement and conversions.
            </motion.p>
          </div>
          <div className="body">
            {featureList.map((feature) => (
              <div
                className={classes.featureContainer}
                style={{ backgroundImage: `url(${feature.background})` }}
              >
                <LazyImage
                  source={feature.icon}
                  alt={feature.heading}
                  className={classes.featureIcon}
                  // alt={listItem.alt}
                />
                <p className="font-wix-semibold font-24 font-white">
                  {feature.heading}
                </p>
                <p className="font-wix-mediu font-18 font-white">
                  {feature.text}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section id="formSection" className={classes.supportSystem}>
        <div className="container">
          <div className="centerContainer">
            <motion.h2
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-medium font-56 font-white"
            >
              Create a <img alt="WhatsApp" src={whatsAppFeatureIcon} /> WhatsApp
              live chat button in seconds
            </motion.h2>
            <motion.p
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-regular font-18 font-white"
            >
              Enhance customer engagement by adding a WhatsApp chat widget to
              your website. Let visitors start a conversation instantly with a
              single click—no coding required.
            </motion.p>
          </div>
          <div className={classes.formBoxContainerBox}>
            <div className={classes.formContainer}>
              <div className={classes.formHeader}>
                <p className="font-28 font-wix-semibold font-black1">
                  Follow these simple steps to generate a WhatsApp chat button
                  for your website
                </p>
              </div>
              <div className={classes.formBody}>
                <motion.div
                  // whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  // transition={{ duration: 1, delay: 0.3 }}
                  className={classes.stepBox}
                >
                  <div
                    className={classes.stepBoxHeader}
                    onClick={() => setActiveStep(0 === activeStep ? -1 : 0)}
                  >
                    <h3 className="font-20 font-wix-semibold">
                      Button appearance
                    </h3>
                    <img
                      src={downArrow}
                      alt="Arrow"
                      className={clsx(
                        classes.downArrow,
                        0 === activeStep && "active"
                      )}
                    />
                  </div>
                  <Collapse
                    className={classes.stepBoxBody}
                    in={activeStep === 0}
                  >
                    <p className="font-16 font-wix-regular color-black1">
                      Choose from multiple attractive button styles to match
                      your brand identity.
                    </p>
                    <div className={classes.optionInputContainer}>
                      <label className="font-16 font-wix-medium color-black1">
                        Button text{" "}
                        <span className="font-16 font-wix-medium color-black4">
                          (Max 25 chars)
                        </span>{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        className={classes.optionInput}
                        type="text"
                        maxLength={25}
                        value={buttonText}
                        onChange={(e) => setButtonText(e.target.value)}
                      />
                      {buttonTextError && (
                        <p
                          className="font-14 font-wix-regular"
                          style={{ color: "red" }}
                        >
                          {buttonTextError}
                        </p>
                      )}
                    </div>
                    <div className={classes.flexOptions}>
                      <div className={classes.optionInputContainer}>
                        <label className="font-16 font-wix-medium color-black1">
                          Brand colour
                        </label>
                        <div className={classes.colorInput}>
                          <div
                            className={clsx(
                              classes.colorPickerContainer,
                              toggleBrandColor && "active"
                            )}
                          >
                            <img
                              src={close}
                              className={classes.closeIcon}
                              onClick={() => setToggleBrandColor(false)}
                            />
                            <HexColorPicker
                              color={brandColor}
                              onChange={setBrandColor} // Corrected to directly set the new color
                            />
                          </div>
                          <div className={classes.colorContainer}>
                            <div
                              className={classes.color}
                              style={{ background: brandColor }}
                              onClick={() => {
                                setToggleBrandColor(true);
                                setToggleButtonColor(false);
                              }}
                            />
                          </div>
                          <p className="font-18 font-wix-medium color-black1">
                            {brandColor}
                          </p>
                        </div>
                      </div>
                      <div className={classes.optionInputContainer}>
                        <label className="font-16 font-wix-medium color-black1">
                          Chat button colour
                        </label>
                        <div className={classes.colorInput}>
                          <div
                            className={clsx(
                              classes.colorPickerContainer,
                              toggleButtonColor && "active",
                              "mobileColorPicker"
                            )}
                          >
                            <img
                              src={close}
                              className={classes.closeIcon}
                              onClick={() => setToggleButtonColor(false)}
                            />
                            <HexColorPicker
                              color={buttonColor}
                              onChange={setButtonColor}
                              // onBlur={() => setToggleButtonColor(false)}
                            />
                          </div>
                          <div className={classes.colorContainer}>
                            <div
                              className={classes.color}
                              style={{ background: buttonColor }}
                              onClick={() => {
                                setToggleButtonColor(true);
                                setToggleBrandColor(false);
                              }}
                            />
                          </div>
                          <p className="font-18 font-wix-medium color-black1">
                            {buttonColor}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={classes.optionInputContainer}>
                      <label className="font-16 font-wix-medium color-black1">
                        Position{" "}
                        <span className="font-16 font-wix-medium color-black4">
                          (Bottom)
                        </span>
                      </label>
                      <div className={classes.colorInput}>
                        <div className={classes.radioGroup}>
                          <div className={classes.radioOption}>
                            <input
                              type="radio"
                              name="position"
                              value="left"
                              id="left"
                              checked={activePosition === "left"}
                              onChange={() => setActivePosition("left")}
                            />
                            <label
                              for="left"
                              className="font-18 font-wix-medium color-black1"
                            >
                              Left
                            </label>
                          </div>
                          <div className={classes.radioOption}>
                            <input
                              id="right"
                              type="radio"
                              name="position"
                              value="right"
                              checked={activePosition === "right"}
                              onChange={() => setActivePosition("right")}
                            />
                            <label
                              for="right"
                              className="font-18 font-wix-medium color-black1"
                            >
                              Right
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={classes.optionInputContainer}>
                      <label className="font-16 font-wix-medium color-black1">
                        Chat button icon
                      </label>
                      <div className={classes.chatButtons}>
                        {chatWidgetIcons.map((chatWidgetIcon, index) => (
                          <div
                            className={clsx(
                              classes.chatButton,
                              selectedIcon.iconName ===
                                chatWidgetIcon.iconName && "active"
                            )}
                            id={chatWidgetIcon.iconName}
                            onClick={() => {
                              setSelectedIcon(chatWidgetIcons[index]);
                              setButtonColor(chatWidgetIcon.colorCode);
                            }}
                          >
                            <div
                              className={classes.chatIcon}
                              style={{ background: chatWidgetIcon.colorCode }}
                            >
                              <img
                                src={chatWidgetIcon.iconSrc}
                                alt={chatWidgetIcon.altText}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Collapse>
                </motion.div>
                <motion.div
                  // whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  // transition={{ duration: 1, delay: 0.3 }}
                  className={classes.stepBox}
                >
                  <div
                    className={classes.stepBoxHeader}
                    onClick={() => setActiveStep(1 === activeStep ? -1 : 1)}
                  >
                    <h3 className="font-20 font-wix-semibold">
                      Chat widget settings
                    </h3>
                    <img
                      src={downArrow}
                      alt="Arrow"
                      className={clsx(
                        classes.downArrow,
                        1 === activeStep && "active"
                      )}
                    />
                  </div>
                  <Collapse
                    className={classes.stepBoxBody}
                    in={activeStep === 1}
                  >
                    <p className="font-16 font-wix-regular color-black1">
                      Edit the widget with your details for a personalised
                      experience
                    </p>
                    <div className={classes.optionInputContainer}>
                      <label className="font-16 font-wix-medium color-black1">
                        Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        className={classes.optionInput}
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter your name"
                      />
                      {nameError && (
                        <p
                          className="font-14 font-wix-regular"
                          style={{ color: "red" }}
                        >
                          {nameError}
                        </p>
                      )}
                    </div>
                    <div className={classes.optionInputContainer}>
                      <label className="font-16 font-wix-medium color-black1">
                        Business email ID{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        className={classes.optionInput}
                        type="text"
                        value={email}
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                      />
                      {emailError && (
                        <p
                          className="font-14 font-wix-regular"
                          style={{ color: "red" }}
                        >
                          {emailError}
                        </p>
                      )}
                    </div>
                    <div className={classes.optionInputContainer}>
                      <label className="font-16 font-wix-medium color-black1">
                        Brand name{" "}
                        <span className="font-16 font-wix-medium color-black4">
                          (Max 50 chars)
                        </span>{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        placeholder="Enter your company name"
                        className={classes.optionInput}
                        type="text"
                        maxLength={50}
                        value={brandName}
                        onChange={(e) => setBrandName(e.target.value)}
                      />
                      {brandError && (
                        <p
                          className="font-14 font-wix-regular"
                          style={{ color: "red" }}
                        >
                          {brandError}
                        </p>
                      )}
                    </div>

                    <div className={classes.optionInputContainer}>
                      <label className="font-16 font-wix-medium color-black1">
                        WhatsApp number{" "}
                        <span className="font-16 font-wix-medium color-black4">
                          (Enter WhatsApp number with country code without '+')
                        </span>{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        className={classes.optionInput}
                        type="text"
                        placeholder="Enter your WhatsApp number"
                        value={phoneNumber}
                        required
                        maxLength={15}
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          const value = e.target.value.replace(/[^0-9]/g, "");
                          if (value.length <= 15) {
                            setPhoneNumber(value);
                          }
                        }}
                      />
                      {phoneError && (
                        <p
                          className="font-14 font-wix-regular"
                          style={{ color: "red" }}
                        >
                          {phoneError}
                        </p>
                      )}
                    </div>
                    <div className={classes.optionInputContainer}>
                      <label className="font-16 font-wix-medium color-black1">
                        Brand image URL
                      </label>
                      <input
                        className={classes.optionInput}
                        type="url"
                        placeholder="Add URL"
                        value={brandUrl}
                        onChange={(e) => setBrandUrl(e.target.value)}
                      />
                    </div>
                    <div className={classes.optionInputContainer}>
                      <label className="font-16 font-wix-medium color-black1">
                        Welcome message{" "}
                        <span className="font-16 font-wix-medium color-black4">
                          (Max 40 chars)
                        </span>
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <textarea
                        className={classes.optionInput}
                        maxLength={40}
                        rows={2}
                        type="text"
                        value={welcomeMessage}
                        onChange={(e) => setWelcomeMessage(e.target.value)}
                      />
                      {welcomeMessageError && (
                        <p
                          className="font-14 font-wix-regular"
                          style={{ color: "red" }}
                        >
                          {welcomeMessageError}
                        </p>
                      )}
                    </div>
                    <div className={classes.optionInputContainer}>
                      <label className="font-16 font-wix-medium color-black1">
                        Pre-filled message - Optional{" "}
                        <span className="font-16 font-wix-medium color-black4">
                          (Max 40 chars)
                        </span>
                      </label>
                      <textarea
                        className={classes.optionInput}
                        maxLength={40}
                        rows={2}
                        type="text"
                        value={preFilledMessage}
                        onChange={(e) => setPreFilledMessage(e.target.value)}
                      />
                    </div>
                    <div className={classes.togglerContainer}>
                      <p className="font-16 font-wix-medium color-black1">
                        Open chat widget by default
                      </p>
                      <div
                        className={clsx(
                          classes.toggler,
                          byDefaultOpen && "active"
                        )}
                        onClick={() => setByDefaultOpen(!byDefaultOpen)}
                      >
                        <div className={classes.toggle} />
                      </div>
                    </div>
                  </Collapse>
                </motion.div>
                <div className={classes.checkboxContainer}>
                  <input
                    type="checkbox"
                    id="check"
                    checked={consent}
                    onClick={() => {
                      setConsent(!consent);
                    }}
                  />
                  <label
                    for="check"
                    onClick={() => {
                      setConsent(!consent);
                    }}
                    className="font-16 font-wix-medium font-black1"
                  >
                    I confirm and agree to the storing and processing of my
                    personal data as described in the{" "}
                    <a target="_blank" href="https://peppercloud.com/privacy">
                      Privacy Statement
                    </a>
                    .
                  </label>
                </div>
                <button
                  className="primaryButton font-18 font-wix-medium font-white"
                  onClick={createChatWidget}
                >
                  Generate WhatsApp chat widget code
                </button>

                {widgetCode && (
                  <div className={classes.widgetCode}>
                    <code className={classes.widgetCodeContainer}>
                      {"<script>"}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {`var url = "https://backend.peppercloud.com/js/widget.js";`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {`var s = document.createElement('script');`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {`s.type = 'text/javascript';`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;s.async = true;
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;s.src = url;
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {`var option = {`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {`"enabled":true,`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {`"chatButtonSetting":{`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {`"backgroundColor":"${selectedIcon.colorCode}",`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {`"ctaText":"",`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {`"borderRadius":"50",`}
                      <br />
                      {`"marginLeft":"${
                        activePosition === "left" ? "50" : "0"
                      }",`}
                      <br />
                      {`"marginRight":"${
                        activePosition === "right" ? "50" : "0"
                      }",`}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {`"marginBottom":"50",`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {`"position":"${activePosition}",`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {`"btnIcon":"${selectedIcon.iconId}",`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {`"type":"ONLY_BUTTON"`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {`},`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {`"brandSetting":{`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {`"brandName":"${brandName}",`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {`"brandSubTitle":"",`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {`"brandImg":"${brandUrl || defaultBrandUrl}",`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {`"welcomeText":"${welcomeMessage}",`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {`"messageText":"${preFilledMessage}",`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {`"backgroundColor":"${brandColor}",`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {`"ctaText":"${buttonText}",`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {`"borderRadius":"${"10"}",`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {`"autoShow":${byDefaultOpen},`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {`"phoneNumber":"${phoneNumber}",`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {`"question":"",`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {`"options":[]`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {`}`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {`};`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {`s.onload = function() {`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CreateWhatsappChatWidget(option);
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {`};`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {`var x = document.getElementsByTagName('script')[0];`}
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;x.parentNode.insertBefore(s,
                      x);
                      <br />
                      {"</script>"}
                      <img
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `<script>
                              var url = "https://backend.peppercloud.com/js/widget.js?v=" + Date.now();
                              var s = document.createElement('script');
                              s.type = 'text/javascript';
                              s.async = true;
                              s.src = url;
                              s.onload = function() {
                                CreateWhatsappChatWidget({
                                  "enabled": true,
                                  "chatButtonSetting": {
                                    "backgroundColor": "${
                                      selectedIcon.colorCode
                                    }",
                                    "ctaText": "",
                                    "borderRadius": "50",
                                    "marginLeft": "${
                                      activePosition === "left" ? "50" : "0"
                                    }",
                                    "marginRight": "${
                                      activePosition === "right" ? "50" : "0"
                                    }",
                                    "marginBottom": "50",
                                    "position": "${activePosition}",
                                    "btnIcon": "${selectedIcon.iconId}",
                                    "type": "ONLY_BUTTON"
                                  },
                                  "brandSetting": {
                                    "brandName": "${brandName}",
                                    "brandSubTitle": "",
                                    "brandImg": "${
                                      brandUrl || defaultBrandUrl
                                    }",
                                    "welcomeText": "${welcomeMessage}",
                                    "messageText": "${preFilledMessage}",
                                    "backgroundColor": "${brandColor}",
                                    "ctaText": "${buttonText}",
                                    "borderRadius": "10",
                                    "autoShow": ${byDefaultOpen},
                                    "phoneNumber": "${phoneNumber}",
                                    "question": "",
                                    "options": []
                                  }
                                });
                              };
                              var x = document.getElementsByTagName('script')[0];
                              x.parentNode.insertBefore(s, x);
                            </script>`
                          );
                          setCopied(true);
                          setTimeout(() => setCopied(false), 3000);
                        }}
                        style={{ cursor: "pointer" }}
                        src={copyIcon}
                        className={classes.copyIcon}
                      />
                    </code>
                  </div>
                )}
              </div>
            </div>
            <div className={classes.qrContainer}>
              <div className={classes.qrContainerInner}>
                <p className="font-wix-semibold font-20 font-black1">
                  Your WhatsApp chat button preview
                </p>
                <div
                  className="chatWidgetContainer"
                  style={{
                    alignItems:
                      activePosition === "left" ? "flex-start" : "flex-end",
                  }}
                >
                  <div className="chatWidgetBox active">
                    <div
                      className="chatWidgetHeaderContainer"
                      style={{ background: brandColor }}
                    >
                      <img
                        className="companyIconChatWidget"
                        src={brandUrl || defaultBrandUrl}
                        alt="Pepper Cloud WhatsApp Chat Button Generator"
                      />
                      <p className="widgetCompanyName">{brandName}</p>
                    </div>
                    <div className="chatWidgetBodyContainer">
                      <div className="message">{welcomeMessage}</div>
                    </div>
                    <div className="chatWidgetFooterContainer">
                      <button
                        className="widgetSendButton"
                        style={{
                          background: brandColor,
                          borderColor: brandColor,
                        }}
                      >
                        {buttonText}
                      </button>
                    </div>
                  </div>
                  <div
                    className="widgetButton"
                    style={{ background: selectedIcon.colorCode }}
                  >
                    <img
                      src={selectedIcon.iconSrc}
                      alt="WhatsApp live chat widget"
                      className="widgetButtonIcon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <motion.div
            initial={{ opacity: 1, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            className={classes.supportBox}
            // style={{ background: point.background }}
          >
            <div className={classes.broadcastLeftContainer}>
              <p className="font-40 font-wix-regular font-white">
                Benefits of using a WhatsApp chat button generator
              </p>
              <div className={classes.pointers}>
                <div className={classes.cardPointer}>
                  <LazyImage
                    source={whiteCheckCircle}
                    className={classes.pointerImg}
                    alt={"pointer"}
                  />
                  <p className="font-wix-regular font-18 font-white">
                    Reduce lead drop-offs and connect with potential clients
                    instantly with the free website chat widget.
                  </p>
                </div>
                <div className={classes.cardPointer}>
                  <LazyImage
                    source={whiteCheckCircle}
                    className={classes.pointerImg}
                    alt={"pointer"}
                  />
                  <p className="font-wix-regular font-18 font-white">
                    Improve customer engagement and satisfaction by enabling
                    customers to connect with you in just a click.
                  </p>
                </div>
                <div className={classes.cardPointer}>
                  <LazyImage
                    source={whiteCheckCircle}
                    className={classes.pointerImg}
                    alt={"pointer"}
                  />
                  <p className="font-wix-regular font-18 font-white">
                    Auto-send welcome message and assist customers to initiate
                    chat with a pre-filled conversation starter.
                  </p>
                </div>
                <div className={classes.cardPointer}>
                  <LazyImage
                    source={whiteCheckCircle}
                    className={classes.pointerImg}
                    alt={"pointer"}
                  />
                  <p className="font-wix-regular font-18 font-white">
                    Boost conversions by enabling potential customers to connect
                    with your business through live chat.
                  </p>
                </div>
              </div>
            </div>
            <div className={classes.broadcastRightContainer}>
              <LazyImage
                source={benifitImage}
                alt={"Benefits of WhatsApp chat button generator"}
                className={classes.broadcastImg}
              />
            </div>
          </motion.div>
        </div>
      </section>
      <section className={classes.experienceContainer} ref={ref}>
        <div className="container">
          <div className={classes.experienceContainerHeader}>
            <motion.h2
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-semibold font-56 text-black1"
            >
              Turn website visits into real conversations
            </motion.h2>
            <motion.p
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-regular font-18 color-black1"
            >
              Enhance customer service, boost lead generation, and build
              meaningful connections across industries with WhatsApp chat
              buttons. Explore the application and use cases of live chat
              widgets.
            </motion.p>
          </div>
          <div className={classes.experienceInnerContainer}>
            <div className={classes.experienceContainerLeft}>
              {experienceList.map((faq, index) => (
                <motion.div
                  whileInView={{ opacity: [0, 1], y: [50, 0] }}
                  transition={{ duration: 1, delay: index * 0.01 }}
                  className={clsx(
                    classes.faqBox,
                    index === activeAccordian && "active"
                  )}
                  onClick={() => setActiveAccordian(index)}
                >
                  <div className={classes.faqHeader}>
                    <h3 className="font-20 font-wix-semibold">{faq.title}</h3>
                    <img
                      src={downArrow}
                      alt="Arrow"
                      className={clsx(
                        classes.downArrow,
                        index === activeAccordian && "active"
                      )}
                    />
                  </div>
                  <Collapse
                    className={classes.body}
                    in={activeAccordian === index}
                  >
                    <p className="font-18 font-wix-regular color-black2">
                      {faq.desc}
                    </p>
                    {/* <motion.img
                      initial={{ opacity: 1, y: 20 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                      src={experienceImg1}
                      alt={faq.alt}
                    /> */}
                  </Collapse>
                </motion.div>
              ))}
            </div>
            <div className={classes.experienceContainerRight}>
              <motion.img
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                src={experienceImg1}
                alt={"WhatsApp chat button generator for retail and eCommerce"}
              />
            </div>
          </div>
        </div>
      </section>
      <section className={classes.growingBusiness}>
        <div className="container">
          <div className="header">
            <motion.h2
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-medium font-56 font-white"
            >
              Why Pepper Cloud CRM is a game-changer for your business
            </motion.h2>
            <motion.p
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-regular font-18 font-white"
            >
              Pepper Cloud CRM makes it easy to create your free WhatsApp chat
              widget and track every lead, conversations, and follow-up—all in
              one place.
            </motion.p>
            <Link
              className="primaryButton font-20 font-wix-medium font-white"
              to="/contact"
            >
              Book a free demo
            </Link>
          </div>
        </div>
        <Swiper
          slidesPerView={3.3}
          // centeredSlides={true}
          spaceBetween={30}
          grabCursor={true}
          loop={false}
          speed={5000}
          modules={[Autoplay]}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          // freeMode={true}
          className={classes.broadcastContainerInner}
          breakpoints={{
            // For smaller screens (mobile)
            0: {
              slidesPerView: 1.3,
              slidesPerGroup: 1,
              freeMode: true,
              grabCursor: false,
              allowTouchMove: true,
              spaceBetween: 10,
            },
            // For larger screens (desktop)
            768: {
              slidesPerView: 3.3,
              centeredSlides: false,
              spaceBetween: 30,
              grabCursor: true,
            },
          }}
        >
          {growingList.map((feature) => (
            <SwiperSlide
              className={classes.featureContainer}
              style={{ background: feature.background }}
            >
              <LazyImage
                source={feature.icon}
                alt={feature.heading}
                className={classes.featureIcon}
                // alt={listItem.alt}
              />
              <p className="font-wix-semibold font-24 color-black1">
                {feature.heading}
              </p>
              <p className="font-wix-mediu font-18 color-black1">
                {feature.text}
              </p>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={classes.absoulteCard}>
          <TruestedSection background={transformBackgroundImage} />
        </div>
      </section>
      <section className={classes.stripSection}>
        <div className="container">
          <div className="centerContainer">
            <motion.h3
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-semibold font-24 font-black1"
            >
              Best AI-powered WhatsApp sales CRM tool - built for growing
              businesses
            </motion.h3>
          </div>
        </div>
        <Swiper
          className={classes.partnerSlider}
          slidesPerView="auto"
          spaceBetween={60}
          loop={true}
          speed={2000}
          modules={[Autoplay]}
          allowTouchMove={false}
          autoplay={{
            delay: 100,
            disableOnInteraction: false,
            pauseOnMouseEnter: false,
          }}
          breakpoints={{
            // For smaller screens (mobile)
            0: {
              freeMode: true,
              grabCursor: false,
              allowTouchMove: true,
              spaceBetween: 20,
            },
            // For larger screens (desktop)
            768: {
              freeMode: false,
              autoplay: {
                delay: 100,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              },
              speed: 2000,
              loop: true,
              allowTouchMove: true,
            },
          }}
        >
          {partners.map((partner) => (
            <SwiperSlide className={classes.partnerSlide}>
              <img src={partner.icon} alt={partner.alt} />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
      <div className={classes.testimonials}>
        <TestimonialSlider
          noButton={true}
          heading={"Success you can see, results you can trust"}
          desc={
            "See how businesses are driving customer engagement with Pepper Cloud."
          }
        />
      </div>
      <div className={classes.wpFaq}>
        <FaqSection
          backgroundColor={"#F1FFF9"}
          heading={"FAQ - WhatsApp chat button/widget generator"}
          faqs={faqs}
        />
      </div>

      <section className={classes.commonPageFooterSection}>
        <div className="container">
          <div className="centerContainer">
            <motion.h2
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-medium font-56 font-white"
            >
              From website visits to real conversations—instantly!
            </motion.h2>
            <button
              className="primaryButton font-20 font-wix-medium font-white"
              onClick={() => scrollToSection()}
            >
              Generate my WhatsApp chat button
            </button>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ChatButtonGenerator;
